import { Injectable } from '@angular/core';
import { Configuration } from './Configuration';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { overrideConfigFromLocalStorage } from '@bluegrace/bs.npmcommon';

@Injectable()
export class ConfigurationService {
  private configData: Configuration;
  private devConfig: Configuration = {
    env: 'DEV',
    carrierServiceUrl: 'https://carrier-api-qa.myblueship.com/api/',
    shipmentServiceUrl: 'https://shipmentapi-qa.myblueship.com/api/v1/',
    logApiUrl: 'http://blueship-api-log.azurewebsites.net',
    carrierOfferUrl: 'https://blueshipdev.myblueship.com/Truck/Index/',
    authUrl: 'https://identity-qa.myblueship.com/',
    authClientID: 'truckload',
    authRedirectURI: 'http://localhost:4200/auth-callback',
    authPostLogoutRedirectURI: 'http://localhost:4200',
    authResponseType: 'id_token token',
    authScope: 'openid profile truckload-api carrier-api',
    authFilterProtocolClaims: true,
    authLoadUserInfo: true,
    enableCarrierFile: true,
    enableManualCarrier: true,
    appInsights_InstrumentationKey: '',
    backofficeShipmentEditUrl: 'https://backoffice-staging.myblueship.com',
    launchDarklyKey: '663011b2823ab90fab21d205',
    disableCarrierSync: 'false',
  };

  constructor(private http: HttpClient) {}

  loadConfigurationData(): Observable<Configuration> {
    const overrideConfig = (config: Configuration) =>
      overrideConfigFromLocalStorage(config, config.env, ['env'], 'ORCA');

    return new Observable<Configuration>((observer) => {
      return this.http.get<Configuration>('/config').subscribe(
        (x) => {
          observer.next(overrideConfig(x));
          observer.complete();
        },
        (err) => {
          observer.next(overrideConfig(this.devConfig));
          observer.complete();
        },
      );
    });
  }
}
