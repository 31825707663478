import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../authentication/_services/auth.service';
import { AppState } from '../../../appstate.model';
import { select, Store } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';
import { CarrierCrmLeadStatus } from '../../../shared/Enums/CarrierCrmLeadStatus';
import { CarrierFileDetails } from '../../models/CarrierFileDetails';
import {
  //LoadCarrierCrmLeadStatus,
  //SetRefreshCarrierCrmLeadStatusOff,
  UpdateCarrierCrmLeadStatus,
} from '../../actions/carrier-details.actions';
import { filter } from 'rxjs/operators';
import { Permissions } from '../../../shared/Enums/Permissions';

@Component({
  selector: 'truckload-carrier-crm-lead-status',
  templateUrl: './carrier-crm-lead-status.component.html',
  styleUrls: ['./carrier-crm-lead-status.component.scss'],
})
export class CarrierCrmLeadStatusComponent implements OnInit {
  showLoading: boolean;
  editCrmStatusForm: FormGroup;
  crmLeadStatusList = Object.values(CarrierCrmLeadStatus);
  carrierFile: CarrierFileDetails;
  selectedCrmLeadStatus = '';
  currentCrmLeadStatus = '';
  serverError: string;

  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.setListeners();
  }

  private setListeners() {
    this.store
      .pipe(
        select((x) => x.CarrierDetails.details),
        filter((details) => details != null),
      )
      .subscribe((x) => {
        this.carrierFile = x;
      });

    this.store
      .pipe(
        select((x) => x.CarrierDetails.crmLeadStatus),
        filter((x) => x != null && x.status.length > 0),
      )
      .subscribe((x) => {
        this.showLoading = x.loading;
        this.currentCrmLeadStatus = (<any>Object)
          .values(CarrierCrmLeadStatus)
          .includes(x.status)
          ? x.status
          : '';
        this.createForm(this.currentCrmLeadStatus);
        //this.store.dispatch(new SetRefreshCarrierCrmLeadStatusOff());
      });

    /*this.store
      .pipe(
        select((x) => x.CarrierDetails.shouldRefreshCrmLeadStatus),
        filter((x) => x === true)
      )
      .subscribe((x) => {
        this.store.dispatch(new LoadCarrierCrmLeadStatus(this.carrierFile.carrierCode));
      });*/
  }

  private createForm(crmLeadStatus: string) {
    this.editCrmStatusForm = new FormGroup({
      crmleadstatus: new FormControl({ value: crmLeadStatus, disabled: this.disableCrmStatusList }),
    });
  }

  onCrmLeadStatusChange(value: string): void {
    this.selectedCrmLeadStatus = value;
  }

  submit(): void {
    this.showLoading = true;
    this.store.dispatch(
      new UpdateCarrierCrmLeadStatus(
        this.carrierFile.carrierCode,
        this.selectedCrmLeadStatus,
      ),
    );
  }

  cancel() {
    this.editCrmStatusForm.reset({
      crmleadstatus: this.currentCrmLeadStatus,
    });
  }

  get disableCrmStatusList() {
    return this.canViewCRMLeadStatus && !this.canEditCRMLeadStatus;
  }

  get canViewCRMLeadStatus(): boolean {
    return this.authService.can(Permissions.ViewCRMLeadStatus);
  }

  get canEditCRMLeadStatus(): boolean {
    return this.authService.can(Permissions.EditCRMLeadStatus);
  }

  get crmLeadstatusChanged(): boolean {
    return (
      this.editCrmStatusForm &&
      this.editCrmStatusForm.get('crmleadstatus').value &&
      this.currentCrmLeadStatus !=
      this.editCrmStatusForm.get('crmleadstatus').value
    );
  }
}
