import { forkJoin } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CarrierCargo, Cargo } from './CarrierCargo';
import { RefService } from '../../../shared/references/ref.service';
import { CargoService } from './cargo.service';
import { CommonService } from '../../../Services/common.service';
import { Permissions } from '../../../shared/Enums/Permissions';
import { AuthService } from '../../../authentication/_services/auth.service';
import { CarrierErrors } from '../CarrierErrors';
import { RefCargo } from '../../../shared/references/RefCargo';
import { RefValue } from '../../../shared/references/RefValue';

@Component({
  selector: 'truckload-cargo-carried',
  templateUrl: './cargo-carried.component.html',
  styleUrls: ['./cargo-carried.component.scss']
})
export class CargoCarriedComponent implements OnInit {
  @Input()
  carrierCode: string;
  @Output()
  openErrorWindow: EventEmitter<CarrierErrors> = new EventEmitter();

  editMode: boolean;
  carrierCargoes: CarrierCargo;
  originalCarrierCargoes: CarrierCargo;
  refCargoes: RefCargo[];

  constructor(
    public refService: RefService,
    public cargoService: CargoService,
    public commonService: CommonService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    forkJoin([
      // these need to be in the same order!
      this.cargoService.getCargoes(this.carrierCode), // 0
      this.refService.getCarrierCargoTypes() // 1
    ]).subscribe((response) => {
      this.setCarrierCargoData(response[0]);
      this.refCargoes = response[1];
    });
  }
  get canEdit(): boolean {
    return this.authService.can(Permissions.EditCargo);
  }
  hasCargo(cargo: RefCargo): boolean {
    return (
      this.carrierCargoes.cargo.find((x) => x.cargoType == cargo.description) !=
      undefined
    );
  }

  changeCheckBox(cargo: RefCargo): void {
    let index = this.carrierCargoes.cargo.findIndex(
      (x) => x.refCargoID === cargo.refCargoID
    );
    if (index != -1) {
      this.carrierCargoes.cargo.splice(index, 1);
      return;
    }

    const newCargo = <Cargo>{
      refCargoID: cargo.refCargoID,
      cargoType: cargo.description,
      carrierCargoID: 0
    };

    this.carrierCargoes.cargo.push(newCargo);
    this.carrierCargoes.cargo = this.sortCargoItems(this.carrierCargoes.cargo);
  }

  edit(): void {
    this.originalCarrierCargoes = Object.assign(
      {} as CarrierCargo,
      this.carrierCargoes
    );
    this.toggleEditMode();
  }

  save(): void {
    this.cargoService
      .updateCarrierCargoes(this.carrierCode, this.carrierCargoes.cargo)
      .subscribe(
        (data) => {
          this.setCarrierCargoData(data);
        },
        (err) => {
          if (err.status === 404) {
            const error = {} as CarrierErrors;
            error.errors = [];
            error.errors.push(err.error);
            error.redirectToCarrierSearch = true;
            this.openErrorWindow.emit(error);
          }
        }
      );
    this.toggleEditMode();
  }

  cancel(): void {
    this.carrierCargoes.cargo = this.originalCarrierCargoes.cargo.map((x) =>
      Object.assign({} as CarrierCargo, x)
    );
    this.toggleEditMode();
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  sortCargoItems(items: Cargo[]): Cargo[] {
    if (items) {
      return items.sort(
        (a, b) =>
          a &&
          a.cargoType &&
          b &&
          b.cargoType &&
          a.cargoType.localeCompare(b.cargoType)
      );
    }

    return items;
  }

  setCarrierCargoData(data: CarrierCargo): void {
    this.carrierCargoes = data;
    this.carrierCargoes.cargo = this.sortCargoItems(
      this.carrierCargoes.cargo
    );
  }
}
